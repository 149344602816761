export default [
  {
    path: '/dashboard',
    name: 'dashboard-partner',
    component: () => import('@/views/Partner/dashboard/dashboard.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/table',
    name: 'table',
    component: () => import('@/views/Partner/table.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit',
    name: 'deposit',
    component: () => import('@/views/Partner/deposit/deposit.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Withdraw',
    name: 'withdraw',
    component: () => import('@/views/Partner/withdraw/withdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Member',
    name: 'member',
    component: () => import('@/views/Partner/member/member.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Member-add',
    name: 'member-add',
    component: () => import('@/views/Partner/member/add.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  // Add agent
  {
    path: '/agent-list',
    name: 'agent-list',
    component: () => import('@/views/Partner/agent/listAgent.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/add-agent',
    name: 'add-agent',
    component: () => import('@/views/Partner/agent/addAgent.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/invite-friend',
    name: 'invite-friend',
    component: () => import('@/views/Partner/invite-friend/view.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/invite-detail',
    name: 'invite-detail',
    component: () => import('@/views/Partner/invite-friend/detail.vue'),
    meta: {
      layout: 'full',
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/invite-detail/:id/:username',
    name: 'invite-detail-byadmin',
    component: () => import('@/views/Partner/invite-friend/detail.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/invite-detail-all/:id',
    name: 'invite-detail-all-byadmin',
    component: () => import('@/views/Partner/invite-friend/detail_all.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/zean-invite-detail-all/:id',
    name: 'invite-detail-all',
    component: () => import('@/views/Partner/invite-friend/detail_all.vue'),
    meta: {
      layout: 'full',
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/zean-invite-add',
    name: 'zean-invite-add',
    component: () => import('@/views/Partner/invite-friend/add_by_zian.vue'),
    meta: {
      layout: 'full',
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/lucky-wheel',
    name: 'lucky-wheel',
    component: () => import('@/views/Partner/wheel/wheel.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  // {
  //   path: '/generate-lottery',
  //   name: 'generate-lot',
  //   component: () => import('@/views/Partner/lottery/generate-lot.vue'),
  //   meta: {
  //     resource: 'User',
  //     action: 'read',
  //   },
  // },
  {
    path: '/seamless-lotto',
    name: 'seamless-lotto',
    component: () => import('@/views/Partner/lottery/seamlesslotto.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/round-open',
    name: 'round-open',
    component: () => import('@/views/Partner/lottery/round.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/lottery-list',
    name: 'lottery-list',
    component: () => import('@/views/Partner/lottery/lottery-list.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/winlose-list',
    name: 'winlose-list',
    component: () => import('@/views/Partner/lottery/winloselog.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/lottery-log',
    name: 'lottery-log',
    component: () => import('@/views/Partner/lottery/lottolog.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/lottery-list-reward',
    name: 'lottery-list-reward',
    component: () => import('@/views/Partner/lottery/lottery-list-reward.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/reward',
    name: 'reward',
    component: () => import('@/views/Partner/lottery/reward.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    component: () => import('@/views/Partner/invite-friend/user_profile.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit-Withdraw/:id',
    name: 'Deposit-Withdraw',
    component: () => import('@/views/Partner/history/deposit_withdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Edit-Data-zean/:id',
    name: 'Deposit-Withdraw-zean',
    component: () => import('@/views/Partner/invite-friend/EditData.vue'),
    meta: {
      layout: 'full',
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/user-detail-zean/:id',
    name: 'user-detail-zean',
    component: () => import('@/views/Partner/invite-friend/userdetail.vue'),
    meta: {
      layout: 'full',
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/History-Play/:id',
    name: 'History-Play',
    component: () => import('@/views/Partner/history/play.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms-deposit',
    name: 'sms-deposit',
    component: () => import('@/views/Partner/sms/smsDeposit.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/sms-withdraw',
    name: 'sms-withdraw',
    component: () => import('@/views/Partner/sms/smsWithdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms-all',
    name: 'sms-all',
    component: () => import('@/views/Partner/sms/smsAll.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/sms-otp',
    name: 'sms-otp',
    component: () => import('@/views/Partner/sms/otp.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/statement',
    name: 'statement',
    component: () => import('@/views/Partner/sms/statement.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/scb-connect',
    name: 'scb-connect',
    component: () => import('@/views/Partner/sms/scbConnect.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/kbank-live',
    name: 'kbank-live',
    component: () => import('@/views/Partner/sms/kbankLive.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/true-money',
    name: 'true-money',
    component: () => import('@/views/Partner/sms/trueMoney.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/waiting-dep',
    name: 'waiting-dep',
    component: () => import('@/views/Partner/sms/waitingDep.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/bank',
    name: 'bank',
    component: () => import('@/views/Partner/admin/bank.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: () => import('@/views/Partner/admin/promotion.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/channel',
    name: 'channel',
    component: () => import('@/views/Partner/admin/channel.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/admin-management',
    name: 'admin-management',
    component: () => import('@/views/Partner/admin/admin.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/admin-agent-management',
    name: 'admin-agent-management',
    component: () => import('@/views/Partner/admin/admin_agent.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/Partner/admin/setting.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/agent-list',
    name: 'agent-list',
    component: () => import('@/views/Partner/admin/agent-list.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/manage-agent/:id',
    name: 'manage-agent',
    component: () => import('@/views/Partner/admin/manage-agent.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/report-win-lose',
    name: 'report',
    component: () => import('@/views/Partner/admin/reports.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/scb-capcha/:ID',
    name: 'scb',
    component: () => import('@/views/Partner/scb.vue'),
    meta: {
      layout: 'full',
      // redirectIfLoggedIn: false,
      resource: 'Auth',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Partner/Login-admin.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/login-admin',
    name: 'auth-login-admin',
    component: () => import('@/views/Partner/Login-admin.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/login2',
    name: 'auth',
    component: () => import('@/views/Partner/login/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
]
